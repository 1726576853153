/**
 * Cookie consent functionality
 */

import customVhProperty from '@valtech/utilities/src/utilities/customVhProperty';

// Required classnames
const classNames = {
  modifiers: {
    autoOpen: 'js-auto-open',
    open: 'is-open',
    shown: 'is-shown',
    sticky: 'cookie-consent--sticky',
  },
  options: 'cookie-consent__options',
  rootNode: 'cookie-consent',
  triggers: {
    consentClose: 'js-cookie-consent-close',
    consentOpen: 'js-cookie-consent-open',
    optionsClose: 'js-trigger-options-close',
    optionsOpen: 'js-trigger-options-open',
  },
};

// Required dom nodes
const elements = {
  rootNode: document.querySelector(`.${classNames.rootNode}`),
  options: document.querySelector(`.${classNames.options}`),
  triggers: {
    consentClose: document.querySelectorAll(`.${classNames.triggers.consentClose}`),
    consentOpen: document.querySelectorAll(`.${classNames.triggers.consentOpen}`),
    optionsClose: document.querySelector(`.${classNames.triggers.optionsClose}`),
    optionsOpen: document.querySelector(`.${classNames.triggers.optionsOpen}`),
  },
};

/**
 * Handles control over diplaying sticky message
 * @returns {undefined}
 */
function displayHandler() {
  const { rootNode } = elements;
  const { consentClose, consentOpen } = elements.triggers;
  const { autoOpen, shown } = classNames.modifiers;

  if (rootNode.classList.contains(autoOpen)) rootNode.classList.add(shown);

  if (consentClose) {
    Array.from(consentClose).forEach((element) => {
      element.addEventListener('click', () => rootNode.classList.remove(shown));
    });
  }

  if (consentOpen) {
    Array.from(consentOpen).forEach((element) => {
      element.addEventListener('click', () => rootNode.classList.add(shown));
    });
  }
}

/**
 * Toggles the options panel display
 * @param {boolean} action Open or close
 * @returns {undefined}
 */
function optionsDisplay(action) {
  const { options } = elements;
  const { open } = classNames.modifiers;

  if (action === null) options.classList.toggle(open);

  if (action) options.classList.add(open);
  else options.classList.remove(open);
}

/**
 * Handle options buttons display
 * @returns {undefined}
 */
function optionsTriggersDisplay() {
  const { optionsClose, optionsOpen } = elements.triggers;
  const { options } = elements;
  const { open } = classNames.modifiers;

  if (options.classList.contains(open)) {
    optionsOpen.style.display = 'none';
    optionsClose.style.display = '';
  } else {
    optionsOpen.style.display = '';
    optionsClose.style.display = 'none';
  }
}

/**
 * Set up click events for options buttons
 * @returns {undefined}
 */
function optionsTriggersSetup() {
  const { optionsClose, optionsOpen } = elements.triggers;

  optionsOpen.addEventListener('click', () => {
    optionsDisplay(true);
    optionsTriggersDisplay();
  });

  optionsClose.addEventListener('click', () => {
    optionsDisplay(false);
    optionsTriggersDisplay();
  });
}

/**
 * Kick off component
 * @returns {undefined}
 */
export default function initCookieConsent() {
  const { rootNode } = elements;
  const { sticky } = classNames.modifiers;

  if (rootNode.classList.contains(sticky)) {
    displayHandler();
    optionsTriggersSetup();
  }
}

// on document ready
document.addEventListener('readystatechange', (e) => {
  if (e.target.readyState === 'complete') {
    initCookieConsent();
    customVhProperty();
  }
});
