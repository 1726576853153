import _ from 'lodash';

/**
 * Get the viewport height and multiple it by 1% to get a value for a vh unit
 * Set the value in the --vh custom property to the root of the document
 * @returns {undefined}
 */
function setValue() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

/**
 * Sets a CSS Custom Property for actual vh value and updates it on resize
 * @see {https://css-tricks.com/the-trick-to-viewport-units-on-mobile} for detailled explanation
 * @returns {undefined}
 */
export default function customVhProperty() {
  setValue();

  window.addEventListener('resize', _.throttle(() => {
    setValue();
  }, 100));

  window.addEventListener('scroll', _.throttle(() => {
    setValue();
  }, 100));
}
